.root {
  height: 80px;
  padding: 16px 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.logo {
  height: 100%;
}

.logo > img {
  height: 100%;
}

.login {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 4px;
  padding-right: 12px;
  outline: none;
  border: none;
  font-size: unset;
  border-radius: 100px;
  background-color: transparent;
  border: 2px solid #9245FF;
  text-decoration: none;
}

.logout {
  text-decoration: none;
  color: white;
  margin-left: 8px;
}
