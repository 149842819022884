.root {
  padding: 8px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  border-radius: 10px;
}

.left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.pp {
  margin-right: 8px;
  border-radius: 6px;
}

.star {
  height: 24px;
  color: red;
}

.tier {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.icon {
  color: green;
}

.tooltip {
  text-align: center !important;
}
