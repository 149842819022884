.root {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 0;
  filter: blur(2px) brightness(0.6);
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.thumbnail {
  border-radius: 6px;
  height: 112px;
  width: 112px;
  padding: 2px;
  transform: scaleY(-1);
}

.line {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}
