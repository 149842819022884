.root {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.background {
  position: absolute;
  z-index: 0;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-image: url(https://w0.peakpx.com/wallpaper/954/727/HD-wallpaper-video-game-trackmania.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  filter: blur(4px) brightness(0.2);
}

.content {
  z-index: 1;
  width: 30%;
  background-color: #1D1F20 !important;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 32px;
}

.bren {
  border-radius: 100% !important;
  background: none !important;
  overflow: hidden;
  padding-bottom: 0px !important;
  height: 64px;
  width: 64px;
}

.link {
  text-decoration: none;
}

.button {
  background-color: purple !important;
  color: var(--palette-common-white);
  gap: 8px;
}