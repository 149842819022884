.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  padding: 16px;
  background-color: var(--background);
  border-radius: 6px;
  width: 540px;
}

.backdrop {
  background-color: grey !important;
  opacity: 0.3 !important;
}

.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px,
}

.title {
  display: block;
  margin-bottom: 16px;
}
