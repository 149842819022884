.root {
  padding: 8px 12px;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 100px;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: white;
}

.disabled {
  cursor: unset;
}

.text {
  display: flex;
  align-items: center;
  gap: 8px;
}