.replacedinput {
  display: block;
  padding: 8px 12px;
  outline: none;
  cursor: pointer;
  background-color: var(--palette-grey-200);
  border-radius: 100px;
  border: 2px solid white;
  display: flex;
  align-items: center;
  gap: 8px;
  width: max-content;
  margin: auto;
  margin-top: 16px;
  color: var(--text);
}

.item::marker {
  color: var(--text);
}
