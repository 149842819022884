.button {
  border: none;
  outline: none;
  font-size: unset;
  background: unset;
  color: lightgrey;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.option {
  padding: 8px;
  outline: none;
  border: none;
  font-size: unset;
  background-color: unset;
  text-align: left;
}

.option:hover {
  background-color: rgb(61, 61, 61);
}