.root {
  position: relative;
  width: 12px;
  height: 12px;
}

.circle {
  width: 100%;
  height: 100%;
  background-color: #62bd19;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  left: 0px;
}

.ring {
  border: 2.5px solid #62bd19;
  border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  left: calc(-6px - 0.5px);
  top: calc(-6px - 0.5px);
  animation: pulsate 2s ease-out;
  animation-iteration-count: infinite; 
  opacity: 0.0
}

@keyframes pulsate {
  50% {transform: scale(0.1, 0.1); opacity: 0.0;}
  75% {opacity: 1.0;}
  100% {transform: scale(1.2, 1.2); opacity: 0.0;}
}