.root {
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: black;
  background: white;
  border: 1px solid var(--palette-grey-200);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
}

.root:hover {
  border-color: var(--palette-grey-400);
}

.root:focus {
  border-color: var(--palette-grey-400);
}

.root:focus-visible {
  outline: 0;
}